import React from "react";

import { Typography, Grid } from "@material-ui/core";

import { Mdx } from "@runly/gatsby-theme";

const Faqs = ({ faqs }) => (
	<Grid container spacing={4}>
		{faqs.map(({ name, childMdx: { frontmatter: { question }, answer } }) => (
			<Grid key={name} item xs={12} md={6} component="article">
				<Faq question={question} answer={answer} />
			</Grid>
		))}
	</Grid>
);

const Faq = ({ question, answer }) => (
	<>
		<Typography variant="h6" component="h3" gutterBottom>
			{question}
		</Typography>
		<Mdx>{answer}</Mdx>
	</>
);

export default Faqs;
