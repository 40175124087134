import React, { useMemo } from "react";

import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemIcon
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@runly/gatsby-theme";

import { formatMoney } from "accounting";
import tc from "tinycolor2";

const PlanSummary = ({ plans, component }) => (
	<Grid
		container
		spacing={1}
		justify="center"
		alignItems="center"
		component={component}
	>
		{plans.map((plan, i) => (
			<Grid key={plan.id} item xs={12} sm={6} md={3}>
				<Plan {...plan} prevPlanName={i > 0 ? plans[i - 1].name : null} />
			</Grid>
		))}
	</Grid>
);

const Plan = ({
	id,
	name,
	description,
	price,
	features,
	isFeatured,
	prevPlanName
}) => {
	const classes = useStyles();

	return (
		<Box mb={4}>
			<Card
				raised
				className={classes.cardBase}
				style={{
					zIndex: isFeatured ? 2 : undefined
				}}
				elevation={isFeatured ? 8 : 2}
			>
				<CardHeader
					title={name}
					titleTypographyProps={{ align: "center" }}
					subheaderTypographyProps={{ align: "center" }}
					className={
						isFeatured ? classes.featuredCardHeader : classes.cardHeader
					}
				/>
				<CardContent className={classes.cardContent}>
					<div className={classes.cardPricing}>
						<Typography component="h2" variant="h3" color="textPrimary">
							{price > 0 ? formatMoney(price, "$", 0) : "Free"}
						</Typography>
						{price > 0 ? (
							<Typography variant="h6" color="textSecondary" title="per month">
								/mo
							</Typography>
						) : null}
					</div>

					<PlanDescription
						prevPlanName={prevPlanName}
						description={description}
						features={features}
					/>
				</CardContent>

				<CtaButton
					planId={price > 0 ? id : null}
					isFeatured={isFeatured}
					title={`Get started with ${name}`}
				>
					Get started
				</CtaButton>
			</Card>
		</Box>
	);
};

const PlanDescription = ({ description, prevPlanName, features }) => {
	const parts = useMemo(() => description.split(/\r?\n/), [description]);
	const classes = useDescriptionStyles();

	const desc = (
		<>
			{parts.map(txt => (
				<Typography key={txt} paragraph>
					{txt}
				</Typography>
			))}
		</>
	);

	const feats = features?.length ? (
		<Typography paragraph>
			All the <em>{prevPlanName}</em> features plus:
			<List>
				{features.map(f => (
					<ListItem key={f} disableGutters>
						<ListItemIcon className={classes.iconHolder}>
							<CheckCircle className={classes.checkIcon} />
						</ListItemIcon>
						<ListItemText primary={f} />
					</ListItem>
				))}
			</List>
		</Typography>
	) : null;

	return (
		<>
			{desc} {feats}
		</>
	);
};

const useDescriptionStyles = makeStyles(theme => {
	return {
		iconHolder: {
			minWidth: theme.spacing(4)
		},
		checkIcon: { color: theme.palette.success.main }
	};
});

const useStyles = makeStyles(theme => ({
	cardHeader: {
		minHeight: theme.spacing(10),
		backgroundColor: tc.mix(
			theme.palette.background.default,
			theme.palette.background.paper
		)
	},
	featuredCardHeader: {
		minHeight: theme.spacing(13),
		backgroundColor: tc.mix(
			theme.palette.background.default,
			theme.palette.background.paper,
			60
		)
	},
	cardContent: {
		minHeight: theme.spacing(45)
	},
	cardPricing: {
		display: "flex",
		justifyContent: "center",
		alignItems: "baseline",
		marginBottom: theme.spacing(2)
	},
	cardFeatures: {
		minHeight: theme.spacing(35)
	},
	cardBase: {
		position: "relative"
	}
}));

const CtaButton = ({ isFeatured, children, planId, title }) => {
	const classes = useButtonStyles();
	const url = planId ? `/dashboard/?planId=${planId}` : "/dashboard/";

	return (
		<Button
			fullWidth
			color="primary"
			variant={isFeatured ? "contained" : "text"}
			size="large"
			to={url}
			className={classes.smallButton}
			classes={{ label: classes.buttonLabel }}
			title={title}
		>
			{children}
		</Button>
	);
};

const useButtonStyles = makeStyles(() => ({
	smallButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: "50vh",
		position: "static"
	},
	buttonLabel: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	}
}));

export default PlanSummary;
