import React, { useMemo } from "react";

import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle as YesIcon, Cancel as NoIcon } from "@material-ui/icons";

import { Link } from "@runly/gatsby-theme";

import { formatNumber } from "accounting";

const PlanComparison = ({ plans, component }) => {
	const PaperContainer = useMemo(
		() => props => <Paper component={component} {...props} />,
		[component]
	);

	return (
		<TableContainer component={PaperContainer}>
			<Table>
				<TableHead>
					<HeaderRow plans={plans} />
				</TableHead>
				<TableBody>
					<FeatureRow name="Included team members" plans={plans}>
						{({ userCount }) => <MeteredFeature count={userCount} />}
					</FeatureRow>

					<FeatureRow name="Multiple owners" plans={plans}>
						{({ userCount }) => <Icon value={userCount > 1} />}
					</FeatureRow>

					<FeatureRow
						name="Ops security role"
						description={
							<>
								Learn more about{" "}
								<Link to="/docs/platform/roles/">role-based access</Link>
							</>
						}
						plans={plans}
					>
						{({ roles }) => <Icon value={roles.indexOf("Ops") >= 0} />}
					</FeatureRow>

					<FeatureRow
						name="Developer security role"
						description={
							<>
								Learn more about{" "}
								<Link to="/docs/platform/roles/">role-based access</Link>
							</>
						}
						plans={plans}
					>
						{({ roles }) => <Icon value={roles.indexOf("Developer") >= 0} />}
					</FeatureRow>

					<FeatureRow
						name="One-step deployment to worker nodes"
						description={
							<>
								Upload jobs as nuget packages via the dotnet/nuget CLIs or the
								Runly web app.
							</>
						}
						plans={plans}
					>
						{() => <Icon value />}
					</FeatureRow>

					<FeatureRow
						name="Automate continuous delivery scenarios"
						description={
							<>
								Setup your CD server to automatically push your jobs to Runly.
							</>
						}
						plans={plans}
					>
						{() => <Icon value />}
					</FeatureRow>

					<FeatureRow
						name="Schedule jobs"
						description={
							<>Queue your jobs to run automatically on a schedule.</>
						}
						plans={plans}
					>
						{() => <Icon value />}
					</FeatureRow>

					<FeatureRow
						name="Run nodes on bare metal or the cloud"
						description={
							<>
								Run worker nodes from your machines or anywhere that supports
								docker.
							</>
						}
						plans={plans}
					>
						{() => <Icon value />}
					</FeatureRow>

					<FeatureRow
						name="Integrate your app"
						description={
							<>
								Schedule, run, and get results of jobs in your frontend or
								backend apps.
							</>
						}
						plans={plans}
					>
						{({ allowApps }) => <Icon value={allowApps} />}
					</FeatureRow>

					<FeatureRow
						name="Job templates"
						description={
							<>Save job configuration per environment and create schedules.</>
						}
						plans={plans}
					>
						{({ templateCount }) => (
							<>
								<MeteredFeature count={templateCount} />{" "}
								<span title="per environment">/ env</span>
							</>
						)}
					</FeatureRow>

					<FeatureRow
						name="Concurrently connected nodes"
						description={
							<>
								The maximum number of worker nodes that can connect to your
								organization at any one time.
							</>
						}
						plans={plans}
					>
						{({ nodeCount }) => <MeteredFeature count={nodeCount} />}
					</FeatureRow>

					<FeatureRow
						name="Environments"
						description={
							<>The number of development and production environments.</>
						}
						plans={plans}
					>
						{({ environmentCount }) => (
							<MeteredFeature count={environmentCount} />
						)}
					</FeatureRow>

					<FeatureRow
						name="Clusters"
						description={
							<>
								A cluster is a{" "}
								<Link to="/docs/nodes-clusters/">group of related nodes</Link>.
							</>
						}
						plans={plans}
					>
						{({ clusterCount }) => <MeteredFeature count={clusterCount} />}
					</FeatureRow>

					<FeatureRow name="Support" plans={plans}>
						{({ price }) =>
							price > 20
								? "Priority Email Support"
								: price > 0
								? "Email Support"
								: "Community Support"
						}
					</FeatureRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const HeaderRow = ({ plans }) => {
	const classes = useRowStyles();

	return (
		<TableRow>
			<TableCell component="th" scope="row" className={classes.feature}>
				Feature
			</TableCell>
			{plans.map(({ id, name }) => (
				<TableCell component="th" scope="col" key={id} align="center">
					{name}
				</TableCell>
			))}
		</TableRow>
	);
};

const FeatureRow = ({ plans, name, description, children }) => {
	const classes = useRowStyles();

	return (
		<TableRow>
			<TableCell component="th" scope="row" className={classes.feature}>
				{name}
				{description ? (
					<Typography variant="caption" color="textSecondary" component="div">
						{description}
					</Typography>
				) : null}
			</TableCell>

			{plans.map(p => (
				<TableCell key={p.id} align="center">
					{children(p)}
				</TableCell>
			))}
		</TableRow>
	);
};

const useRowStyles = makeStyles(theme => ({
	feature: {
		maxWidth: theme.spacing(25)
	}
}));

const MeteredFeature = ({ count }) => {
	if (count === null || count === undefined) {
		return <>Unlimited</>;
	}

	return <>{formatNumber(count)}</>;
};

const Icon = ({ value }) => {
	const classes = useIconStyles();

	if (value) {
		return <YesIcon titleAccess="Yes" className={classes.yes} />;
	}

	return <NoIcon titleAccess="No" className={classes.no} />;
};

const useIconStyles = makeStyles(theme => ({
	yes: {
		color: theme.palette.success.main
	},
	no: {
		color: theme.palette.text.secondary
	}
}));

export default PlanComparison;
